.certs-header {
  font-size: 40px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

.certs-body-div {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media (max-width: 1380px) {
  .certs-header {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .certs-body-div {
    flex-direction: column;
  }
  .certs-header {
    font-size: 30px;
  }
}
